export const getConnectedNodesAndEdges = (nodeId, nodes, edges) => {
    if (!nodeId || !edges || !nodes) {
        console.error("Invalid inputs provided.");
        return null;
    }

    // Initialize sets for storing visited nodes and relevant edges
    const visitedNodeIds = new Set(); // Keep track of already-visited nodes
    const relevantEdges = []; // Store relevant edges

    // Recursive function to traverse connected nodes
    const traverseEdges = (currentNodeId) => {
        // Stop if we already visited this node
        if (visitedNodeIds.has(currentNodeId)) {
            return;
        }

        // Add the node ID to visited
        visitedNodeIds.add(currentNodeId);

        // Find all edges where the current node is either a source or a target
        const directlyConnectedEdges = edges.filter(
            (edge) => edge.source === currentNodeId || edge.target === currentNodeId
        );

        // Add these edges to the relevantEdges list if not already added
        directlyConnectedEdges.forEach((edge) => {
            if (!relevantEdges.includes(edge)) {
                relevantEdges.push(edge);

                // Identify the other node connected to the current edge
                const connectedNodeId = edge.source === currentNodeId ? edge.target : edge.source;

                // Recursively traverse the connected node
                traverseEdges(connectedNodeId);
            }
        });
    };

    // Start traversing from the given nodeId
    traverseEdges(nodeId);

    // Filter nodes based on the visitedNodeIds
    const filteredNodes = Array.from(visitedNodeIds)
        .map((id) => nodes.find((node) => node.id === id))
        .filter((node) => node); // Exclude null/undefined nodes

    // Keep the relevantEdges as is without modification
    const filteredEdges = relevantEdges;

    // Return the filtered context data
    return {
        nodes: filteredNodes,
        edges: filteredEdges,
    };
};

export const generateMessages = (connectedNodes, additionalMessages = null) => {
    /**
     * Generate an array of messages for use with OpenAI APIs based on graph-based inputs.
     * This function traverses the graph recursively, starting from root nodes,
     * builds a hierarchical and ordered message array, and adds optional messages at the end.
     */

    // Helper function to find root nodes (nodes with no incoming edges).
    const nodes = connectedNodes.nodes;
    const edges = connectedNodes.edges;
    


    const findRootNodes = (nodes, edges) => {
        const allNodeIds = nodes.map((node) => node.id);
        const targetNodeIds = new Set(edges.map((edge) => edge.target));
        return nodes.filter((node) => !targetNodeIds.has(node.id));
    };

    // Helper function to check if a title is wrapped in {curly braces}.
    const isSystemTitle = (title) => {
        return (
            title &&
            title.trim().startsWith("{") &&
            title.trim().endsWith("}")
        );
    };

    // Helper function to strip curly braces and spaces from titles.
    const formatTitle = (title) => {
        if (!title) return "";
        const stripped = title
            .replace(/{|}/g, "")
            .replace(/\s+/g, "");
        return stripped;
    };

    // Helper function to format message content.
    const formatMessageContent = (node) => {
        const strippedTitle = formatTitle(node.title);
        return `[Nodeid]=${node.id} [Title]=${strippedTitle} [Brief]=${node.brief}`;
    };

    // Recursive function for hierarchical graph traversal.
    const processNode = (node, edges, visitedNodes, hierarchy = []) => {
        // Avoid infinite loop by checking if the node has already been visited.
        if (visitedNodes.has(node.id)) {
            return;
        }

        // Mark the node as visited.
        visitedNodes.add(node.id);

        // Add the current node to the hierarchical result array.
        hierarchy.push(node);

        // Retrieve the child nodes connected to the current node.
        const childNodes = edges
            .filter((edge) => edge.source === node.id)
            .map((edge) =>
                connectedNodes.nodes.find((n) => n.id === edge.target)
            )
            .filter((child) => child); // Exclude null/undefined (in case of invalid data).

        // Sort child nodes by coordinates (Y first, then X if Y is identical).
        childNodes.sort((a, b) => {
            if (a.y === b.y) {
                return a.x - b.x;
            }
            return a.y - b.y;
        });

        // Recursively process each child node.
        childNodes.forEach((childNode) => processNode(childNode, edges, visitedNodes, hierarchy));
    };

    // Defensive validation for node coordinates.
    connectedNodes.nodes.forEach((node) => {
        if (typeof node.x === "undefined" || typeof node.y === "undefined") {
            console.error(`Node ${node.id} is missing x or y coordinates.`);
        }
    });

    // Generate the message array.
    const messages = [];

    // First, add the initial instruction message for the EDGES content.
    messages.push({
        role: "user",
        content: `{EDGES} = """ ${JSON.stringify(
            connectedNodes.edges,
            null,
            2
        )} """`,
    });

    // Second, add the system message for graph traversal instructions.
    messages.push({
        role: "system",
        content:
            "You are an expert in graph traversal. The {EDGES} represent a PERT diagram that can have multiple root nodes and multiple end nodes. Use the {EDGES} in your thinking process to find concepts and relationships before answering questions.",
    });

    // Initialize hierarchical traversal from root nodes.
    const rootNodes = findRootNodes(
        connectedNodes.nodes,
        connectedNodes.edges
    );

    // Sort root nodes by their coordinates (Y first, then X if Y is identical).
    rootNodes.sort((a, b) => {
        if (a.y === b.y) {
            return a.x - b.x;
        }
        return a.y - b.y;
    });

    const visitedNodes = new Set();
    const orderedNodes = [];

    // Process each root node recursively.
    rootNodes.forEach((rootNode) => {
        processNode(rootNode, connectedNodes.edges, visitedNodes, orderedNodes);
    });

    // Convert the ordered nodes to messages.
    orderedNodes.forEach((node) => {
        const messageContent = formatMessageContent(node);

        if (isSystemTitle(node.title)) {
            // Nodes with a title wrapped in {} are system messages.
            messages.push({
                role: "system",
                content: messageContent,
            });
        } else {
            // All other nodes are user messages.
            messages.push({
                role: "user",
                content: messageContent,
            });
        }
    });

    // Append optional additional messages, if provided.
    if (additionalMessages) {
        messages.push(...additionalMessages);
    }

    // Return the generated message array.
    return { messages };
};